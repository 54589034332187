import { setHeaderToken } from '../services';
import { store } from 'react-notifications-component';

export const onInitializeOvermind = async ({ state, effects }) => {
  state.isAuthenticating = true;
  effects.api.initialize().then(async (token) => {
    try {
      state.currentUser = await effects.api.getUserMe();
      state.badges = await effects.api.getBadges();
    } catch (err) {
      localStorage.clear();
      store.addNotification({
        title: 'Unauthorized!',
        message: `Session Expired!`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
    }
    state.isAuthenticating = false;
  });
};

export const getUserMe = async ({ state, effects }) => {
  state.currentUser = await effects.api.getUserMe();
};

export const userLogin = async ({ state, effects }, payload) => {
  try {
    let { data } = await effects.userLogin(payload);
    if (data.data.access_token != null) {
      localStorage.setItem('access_token', data.data.access_token);
      await setHeaderToken(data.data.access_token).then(async () => {
        state.currentUser = await effects.api.getUserMe();
        state.badges = await effects.api.getBadges();
        state.isAuthenticated = true;
      });
      return data;
    }
    else {
      return data;
    }
  } catch (error) {
    state.currentUser = null;
    throw error;
  }
};

export const userValidateOtp = async ({ state, effects }, payload) => {
  try {
    let { data } = await effects.adminValidateOtp(payload);
    if (data.data.access_token != null) {
      localStorage.setItem('access_token', data.data.access_token);
      await setHeaderToken(data.data.access_token).then(async () => {
        state.currentUser = await effects.api.getUserMe();
        state.badges = await effects.api.getBadges();
        state.isAuthenticated = true;
      });
      return data;
    }
  } catch (error) {
    state.currentUser = null;
    throw error;
  }
};

export const userLogout = async ({ state }) => {
  localStorage.clear();
  state.isAuthenticating = false;
  state.isAuthenticated = false;
  state.currentUser = {};
  state.badges = null;
};
