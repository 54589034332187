import moment from 'moment';
import React, { useState, useEffect, useMemo, useContext, lazy, Suspense } from 'react';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import * as API from '../../services/api';
import { Notification } from '../../utils/index.js';
import ApproveFunding from './components/ApproveFunding.js';
import CancelFunding from './components/CancelFunding.js';
import CancelledInvestmentsList from './components/CancelledInvestmentsList.js';
import IssuerPayNow from './components/IssuerPayNow.js';
import PublishProject from './components/PublishProject.js';
// import ProjectDetails from './components/ProjectDetails.js';
const ProjectDetails = lazy(() => import('.//components/ProjectDetails.js'));
const LoanSchedule = lazy(() => import('./components/LoanSchedule.js'));
const LoanStatement = lazy(() => import('./components/LoanStatement.js'));
const PaymentRecord = lazy(() => import('./components/PaymentRecord.js'));
const InvestorList = lazy(() => import('./components/InvestorList.js'));
const InvestorCancelledList = lazy(() => import('./components/InvestorCancelledList.js'));

const ProjectView = () => {
  const pageContext = useContext(PageContext);
  const history = useHistory();
  const { project_id } = useParams();

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);
  }, []);

  const [tabs, setActiveTab] = useState({
    summary: true,
    loanSchedule: false,
    activities: false,
    documents: false,
    signatories: false,
    transactions: false,
    investors: false,
    referrals: false,
    commissions: false,
    autoInvestmentRules: false
  });

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      loanSchedule: tab === 'loanSchedule' ? true : false,
      paymentRecord: tab === 'paymentRecord' ? true : false,
      loanStatement: tab === 'loanStatement' ? true : false,
      activities: tab === 'activities' ? true : false,
      investors: tab === 'investors' ? true : false,
      cancelled: tab === 'cancelled' ? true : false
    });
  };

  const showCFForm = () => {
    window.open(project.sec_cf_link, '_blank');
  };

  const showCFOForm = () => {
    window.open(project.sec_cfo_link, '_blank');
  };

  const [project, setProject] = useState({});

  const onPublishProject = () => {
    API.publishProject(project_id)
      .then(() => {
        history.go(0);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  // 2022-05-28
  const onApproveFunding = (startDate) => {
    API.approveProject(project_id, {
      repayment_start_date: moment(startDate).format('YYYY-MM-DD')
    })
      .then(() => {
        history.go(0);
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onCancelFunding = () => {
    API.cancelProject(project_id)
      .then(() => {
        Notification.success('Project Cancel', 'Project Successfully Cancelled');
        history.go(0);
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  useEffect(() => {
    if (project_id) {
      API.getProject(project_id).then((res) => {
        setProject(res.data.data);
      });
    }
  }, [project_id]);

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            {/* <div className="profile-header-img">
              <img src={loan.banner} alt="" />
            </div> */}
            <div className="profile-header-info">
              <div className="d-flex align-items-center">
                <h4>
                  {project.company_name} ({project.funding_id}){' '}
                </h4>
                <span className="badge bg-primary rounded-pill ml-2">
                  {project?.status?.name || ''}
                </span>

                {project?.is_default == 1 && <span className="badge bg-danger ml-2 mt-0">
                  Default
                </span>}

                {project?.is_disbursed == 0 && <span className="badge bg-info ml-2 mt-0">
                  Undisbursed
                </span>}

              </div>
              {/* <div style={{ width: '300px', marginBottom: '10px' }}>
                <div className="progress h-10px rounded-pill mb-5px">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-orange fs-10px fw-bold"
                    style={{ width: '30%' }}
                  >
                    Progress (30%)
                  </div>
                </div>
              </div> */}
              {/* <Link to="/extra/profile" className="btn btn-xs btn-green m-l-5">
                Approve
              </Link> */}

              <a onClick={showCFForm} className="btn btn-xs btn-info m-l-5">
                <i className="fa fa-file-pdf mr-1"></i>SEC Form CF
              </a>
              <a onClick={showCFOForm} className="btn btn-xs btn-info m-l-5">
                <i className="fa fa-file-pdf mr-1"></i>SEC Form CF-O
              </a>
              <Link to={`/projects/update/${project.id}`} className="btn btn-xs btn-blue m-l-5">
                <i className="fa fa-edit mr-1"></i>Edit
              </Link>
              {/* <Link
                to={`/projects/update/${project.id}`}
                className="btn btn-xs btn-green m-l-5 text-white"
              >
                <i className="fa fa-thumbs-up mr-1"></i>Publish
              </Link> */}
              {project.is_published == 0 && <PublishProject onConfirm={onPublishProject} />}
              {project.status && project.status.id == 1 && project.funding_progress == 100 && (
                <>
                  <CancelFunding onConfirm={onCancelFunding} />
                  <ApproveFunding onConfirm={onApproveFunding} />
                </>
              )}

              {/* <Link to="/extra/profile" className="btn btn-xs btn-danger m-l-5">
                <i className="fa fa-exclamation-triangle mr-1"></i> Re-structure
              </Link> */}
              {/* <IssuerPayNow /> */}
              {/* <Link to="/extra/profile" className="btn btn-xs btn-indigo m-l-5">
                <i className="fa fa-money-check-alt mr-1"></i>Issuer Pay Now
              </Link> */}
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs" style={{ paddingLeft: 10 }}>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                PROJECT DETAILS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'loanSchedule')}
                className={'nav-link ' + (tabs.loanSchedule ? 'active ' : '')}
              >
                LOAN SCHEDULE
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'paymentRecord')}
                className={'nav-link ' + (tabs.loanSchedule ? 'active ' : '')}
              >
                PAYMENT RECORD
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'loanStatement')}
                className={'nav-link ' + (tabs.loanStatement ? 'active ' : '')}
              >
                LOAN STATEMENT
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'investors')}
                className={'nav-link ' + (tabs.investors ? 'active ' : '')}
              >
                INVESTORS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'cancelled')}
                className={'nav-link ' + (tabs.cancelled ? 'active ' : '')}
              >
                CANCELLED INVESTMENTS
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'activities')}
                className={'nav-link ' + (tabs.activities ? 'active ' : '')}
              >
                ACTIVITY LOGS
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              <Suspense fallback={<div>Loading...</div>}>
                <ProjectDetails project={project} />
              </Suspense>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.loanSchedule ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              {tabs.loanSchedule && (
                <Suspense fallback={<div>Loading...</div>}>
                  <LoanSchedule />
                </Suspense>
              )}
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.paymentRecord ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              {tabs.paymentRecord && (
                <Suspense fallback={<div>Loading...</div>}>
                  <PaymentRecord />
                </Suspense>
              )}
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.loanStatement ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              {tabs.loanStatement && (
                <Suspense fallback={<div>Loading...</div>}>
                  <LoanStatement />
                </Suspense>
              )}
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.investors ? 'show active ' : '')}>
            <div className="col-12">
              <InvestorList />
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.cancelled ? 'show active ' : '')}>
            <div className="col-12">
              <CancelledInvestmentsList total={project.total_cancel_investments} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProjectView);
